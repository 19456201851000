<template>
  <div class="input-image">
    <v-img
      :lazy-src="lazySrc"
      :max-height="300"
      :max-width="300"
      :src="internalSrc"
      class="rounded"
      contain
    >
      <template v-slot:default>
        <div class="upload-control">
          <t-upload-image
            v-slot="{
              on,
              isCompressing,
              isCompressed,
              compareImage
            }"
            class="w-full"
            v-bind="uploadImageOptions"
            @change="onChangeImage"
          >
            <v-btn
              v-if="isCompressed"
              block
              class="upload-control--btn"
              color="grey lighten-2"
              icon
              x-small
              @click="compareImage"
            >
              <v-icon>mdi-compare</v-icon>
            </v-btn>

            <v-btn
              :loading="isCompressing"
              block
              class="upload-control--btn"
              color="grey lighten-2"
              icon
              x-small
              v-on="on"
            >
              <v-icon>mdi-image-edit</v-icon>
            </v-btn>
          </t-upload-image>
        </div>

        <v-btn
          v-if="src"
          class="btn-close"
          fab
          icon
          small
          @click="onChangeImage(null)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <template v-slot:placeholder>
        <v-row align="center" class="fill-height ma-0" justify="center">
          <v-progress-circular
            color="grey lighten-2"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <tp-text-field
      :hide-details="!required"
      :validate="required ? 'required' : ''"
      :value="file"
      class="mt-2"
    ></tp-text-field>
  </div>
</template>

<script>
import TUploadImage from "@vt7/upload-image/lib/t-upload-image.vue";
import { isString } from "@vt7/utils";

export default {
  components: { TUploadImage },

  props: {
    acceptedFileTypes: { type: Array },
    allowFileTypeValidation: { type: Boolean, default: false },
    allowConvertFileType: { type: Boolean, default: false },
    allowResizeFile: { type: Boolean, default: false },
    allowFileDimensionValidation: { type: Boolean, default: false },
    allowFileSize: { type: Boolean, default: false },
    allowCompress: { type: Boolean, default: false },
    forceType: { type: String },
    maxFileSize: { type: Number },
    height: { type: Number },
    width: { type: Number },
    src: { type: [String, File], default: "" },
    required: { type: Boolean, default: false }
  },

  data() {
    return {
      file: null,
      uploadImageOptions: {
        acceptedFileTypes: this.acceptedFileTypes,
        allowFileTypeValidation: this.allowFileTypeValidation,
        allowFileDimensionValidation: this.allowFileDimensionValidation,
        allowResizeFile: this.allowResizeFile,
        targetSize: { width: this.width, height: this.height },
        minSize: { width: this.width, height: this.height },
        forceType: this.forceType,
        allowFileSize: this.allowFileSize,
        allowCompareImage: this.allowCompress,
        allowCompress: this.allowCompress,
        maxFileSize: this.maxFileSize,
        shortPixelApiKeys: process.env.VUE_APP_COMPRESS_API_KEYS.split(",")
      }
    };
  },

  computed: {
    lazySrc() {
      return require("@/core/assets/images/default-image.png");
    },
    internalSrc() {
      if (!this.src) return this.lazySrc;
      if (isString(this.src)) return this.src;
      return URL.createObjectURL(this.src);
    }
  },

  methods: {
    onChangeImage(file) {
      this.file = file;
      this.$emit("update:src", file);
    }
  },

  watch: {
    src: {
      handler(value) {
        this.file = value;
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.input-image {
  display: flex;
  flex-direction: column;
  width: 100%;

  .v-image {
    border: 1px solid #e5e5e5;
    height: 100%;
  }

  ::v-deep .v-input__slot {
    display: none;
  }

  .btn-close {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.upload-control {
  align-items: flex-end;
  display: flex;
  height: 100%;

  &--btn {
    background-color: rgba(0, 0, 0, 0.4) !important;
    border-radius: 0 !important;
  }
}
</style>
